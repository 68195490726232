import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  preserveAspectRatio: "none",
  width: "520",
  height: "77pt",
  "stroke-miterlimit": "10",
  style: {"fill-rule":"nonzero","clip-rule":"evenodd","stroke-linecap":"round","stroke-linejoin":"round"},
  viewBox: "0 0 390 77"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("clipPath", { id: "a" }, [
      _createElementVNode("path", { d: "M0 0h390v77H0z" })
    ], -1),
    _createElementVNode("g", { "clip-path": "url(#a)" }, [
      _createElementVNode("path", {
        fill: "currentColor",
        d: "M640.97 261.381c10.298-34.799 31.865-107.757-5.437-177.903-29.757-55.915-88.678-94.605-147.993-103.62-91.502-13.917-111.684 52.996-232.773 85.234C87.015 109.748 4.823 9.252-131.405 79.606c-17.688 9.151-65.106 36.127-93.402 90.099-53.693 102.533 6.345 219.661 23.266 252.762 6.247 12.175 80.496 151.571 236.694 198.327 256.338 76.814 544.589-152.243 605.817-359.413Z"
      })
    ], -1)
  ])))
}
export default { render: render }