import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  preserveAspectRatio: "none",
  width: "240.231",
  height: "732",
  "stroke-miterlimit": "10",
  style: {"fill-rule":"nonzero","clip-rule":"evenodd","stroke-linecap":"round","stroke-linejoin":"round"},
  viewBox: "0 0 180.173 549"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("clipPath", { id: "a" }, [
      _createElementVNode("path", { d: "M0 0h180.173v549H0z" })
    ], -1),
    _createElementVNode("g", { "clip-path": "url(#a)" }, [
      _createElementVNode("path", {
        fill: "currentColor",
        d: "M-574.686 922.898c51.427 39.879 159.259 123.452 300.754 106.842 112.804-13.28 215.834-86.507 269.546-179.726 82.873-143.8-15.765-221.117 9.28-444.42C39.596 96.244 261.04 24.414 232.276-249.017c-3.761-35.513-17.931-132.31-89.662-214.75C6.305-620.279-228.494-596.298-294.82-589.599c-24.411 2.504-305.673 35.716-485.564 266.201C-1075.74 54.797-880.897 685.615-574.686 922.898Z"
      })
    ], -1)
  ])))
}
export default { render: render }